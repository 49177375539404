import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { Button } from "@chakra-ui/react";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import SidebarComponent from "./SidebarComponet";

type ListItem = {
  label: string;
  icon: string;
  link: string;
};

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [selectedList, setSelectedList] = useState<ListItem[]>([]);
  const [subAdmin, setSubAdmin] = useState<string[] | undefined>();

  const selector = useSelector((state: RootState) => state.user);
  const usrRole = selector?.user?.user.role;
  const adminAccess = selector?.subAdmin?.accesses;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const allOptions: ListItem[] = [
    {
      label: "Dashboard",
      icon: "/sidebaricons/Dashboard.png",
      link: "/dashboard",
    },
    { label: "User", icon: "/sidebaricons/user.png", link: "/user" },
    { label: "Courses", icon: "/sidebaricons/courses.png", link: "/course" },
    { label: "Category", icon: "/sidebaricons/category.png", link: "/category" },
    {
      label: "Instructor",
      icon: "/sidebaricons/instructor.png",
      link: "/instructor",
    },
    {
      label: "Subscription",
      icon: "/sidebaricons/subscriptions.png",
      link: "/subscription",
    },
    {
      label: "Resources",
      icon: "/sidebaricons/resources.png",
      link: "/resources",
    },
    {
      label: "Announcements",
      icon: "/sidebaricons/announcements.png",
      link: "/announcement",
    },
    { label: "Events", icon: "/sidebaricons/Events.png", link: "/event" },
    {
      label: "Advertisers",
      icon: "/sidebaricons/Advertisers.png",
      link: "/advertiser",
    },
    {
      label: "Support Chat",
      icon: "/sidebaricons/SupportChat.png",
      link: "/support_chat",
    },
    {
      label: "Push Notification",
      icon: "/sidebaricons/PushNotifications.png",
      link: "/push_notification",
    },
    {
      label: "All Feedback",
      icon: "/sidebaricons/AllFeedback.png",
      link: "/feedback",
    },
  ];

  useEffect(() => {
    if (adminAccess) {
      setSubAdmin(adminAccess);
    } else {
      const subAdminJson = localStorage.getItem("access");
      if (subAdminJson) {
        try {
          const parsedSubAdmin = JSON.parse(subAdminJson);
          setSubAdmin(parsedSubAdmin.accesses);
        } catch (error) {
          setSubAdmin(undefined);
        }
      } else {
        setSubAdmin(undefined);
      }
    }
  }, [adminAccess]);

  useEffect(() => {
    if (usrRole === 1) {
      setSelectedList(allOptions);
    } else if (usrRole === 3 && subAdmin !== undefined) {
      const filteredOptions = allOptions.filter((option) =>
        subAdmin.includes(option.link.trim().substring(1))
      );
      setSelectedList([
        {
          label: "Dashboard",
          icon: "/sidebaricons/Dashboard.png",
          link: "/dashboard",
        },
        ...filteredOptions,
      ]);
    }
  }, [usrRole, subAdmin]);

  return (
    <div>
      <div className="menu-icon">
        <Button onClick={toggleDrawer(true)}>
          <MenuIcon color={"primary"} />
        </Button>
      </div>

      {/* Error :this block is giving error */}
      {/* <Drawer open={open} onClose={toggleDrawer(false)}>
        <div className="mobileview">
          <SidebarComponent selectedList={selectedList} />
        </div>
      </Drawer> */}

      <div className="sidebar">
        <SidebarComponent selectedList={selectedList} />
      </div>
    </div>
  );
};

export default Sidebar;
