import {
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import AllTickets from "./SupportChat/AllTickets";
import ChatView from "./SupportChat/ChatView";
import { SupportTicket } from "../@types/user";
import { SearchSupportTickets } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ClipLoader } from "react-spinners";

const SupporChat = ({
  supportTickets,
  setsupportTickets,
  searchKey,
  setsearchKey,
  currentPage,
  setCurrentPage,
  isLoading,
}: {
  searchKey: string;
  setsupportTickets: Dispatch<SetStateAction<SupportTicket[]>>;
  setsearchKey: Dispatch<SetStateAction<string>>;
  supportTickets: SupportTicket[];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
}) => {
  const [selectedTicketindex, setSelectedTicketindex] = useState<number>(0);

  // const [currentPage, setCurrentpage] = useState(0);
  const selector = useSelector((state: RootState) => state.user);

  const usr = selector.user;
  const searchSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && searchKey.trim() !== "" && searchKey.trim().length>0) {
        const res = (await SearchSupportTickets(
          usr.token,
          searchKey,
          10
        )) as SupportTicket[];
        setsupportTickets(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = () => {
    setsearchKey("");
  };

  // for infinite scroll of chat list
  const bottomboundaryRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        setCurrentPage((prev) => prev + 1);
      }
    });
    const currentBottomBoundaryRef = bottomboundaryRef.current;
    if (currentBottomBoundaryRef) {
      observer.observe(currentBottomBoundaryRef);
    }
    return () => {
      if (currentBottomBoundaryRef) {
        observer.unobserve(currentBottomBoundaryRef);
      }
    };
  }, []);

  return (
    <div className="w-[100%] lg:w-[95%] md:w-[95%] m-auto h-[80vh] px-6 lg:px-4 md:px-4 bg-white shadow-md rounded-lg">
      <Tabs variant="soft-rounded" className="px-2" onChange={handleTabChange}>
        <TabList className="py-4">
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"xs"}
          >
            All Support Ticket
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"xs"}
            marginLeft={"12px"}
          >
            Open Ticket
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"xs"}
            marginLeft={"12px"}
          >
            Closed Ticket
          </Tab>
        </TabList>
        <div className="flex">
          <TabPanels
            maxWidth={"24vw"}
            height={"66vh"}
            maxHeight={"78vh"}
            overflow={"scroll"}
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 0.2)",
              scrollbarWidth: "none",
            }}
          >
            <TabPanel paddingY={0} paddingX={3} position="relative">
              <div className="w-full bg-white sticky top-0 z-10 pb-2">
                <form onSubmit={searchSubmit}>
                  <Input
                    onChange={(e) => {
                      setsearchKey(e.target.value.trimStart());
                    }}
                    className="w-full"
                    type="text"
                    placeholder="Search here"
                    value={searchKey}
                  />
                </form>
              </div>
              <div>
                {supportTickets.map((item, index) => (
                  <div
                    onClick={() => {
                      setSelectedTicketindex(index);
                    }}
                    key={index}
                    className="pt-2"
                  >
                    <AllTickets
                      supportChat={item}
                      setsupportTickets={setsupportTickets}
                    />
                  </div>
                ))}
              </div>
              <div ref={bottomboundaryRef} className="w-100 h-[30px]">
                {/* if scrolled here then load and change set current page to +1  */}
              </div>
              {isLoading && (
                <div className="w-100 flex justify-center">
                  <ClipLoader />
                </div>
              )}
            </TabPanel>

            <TabPanel paddingY={0} paddingX={3} position="relative">
              <div className="w-full bg-white sticky top-0 z-10 pb-2">
                <form onSubmit={searchSubmit}>
                  <Input
                    onChange={(e) => {
                      setsearchKey(e.target.value.trimStart());
                    }}
                    className="w-full"
                    type="text"
                    value={searchKey}
                    placeholder="Search here"
                  />
                </form>
              </div>
              <div>
                {supportTickets.map((item, index) => (
                  <div
                    onClick={() => {
                      setSelectedTicketindex(index);
                    }}
                    className="pt-2"
                  >
                    {item.status === 1 && <AllTickets supportChat={item} />}
                  </div>
                ))}
              </div>
            </TabPanel>

            <TabPanel paddingY={0} paddingX={3} position="relative">
              <div className="w-full bg-white sticky top-0 z-10 pb-2">
                <form onSubmit={searchSubmit}>
                  <Input
                    onChange={(e) => {
                      setsearchKey(e.target.value.trimStart());
                    }}
                    className="w-full"
                    value={searchKey}
                    type="text"
                    placeholder="Search here"
                  />
                </form>
              </div>
              {supportTickets.map((item, index) => (
                <div
                  onClick={() => {
                    setSelectedTicketindex(index);
                  }}
                >
                  {item.status === 2 && <AllTickets supportChat={item} />}
                </div>
              ))}
            </TabPanel>
          </TabPanels>
          <div className="w-[100%] pl-3 h-[50vh]">
            <ChatView
              setsupportTickets={setsupportTickets}
              selectedTicket={supportTickets[selectedTicketindex]}
            />
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default SupporChat;
