import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import CreateAdivertisers from "./modal/CreateAdivertisers";
import { AdvertisersType, UserType } from "../@types/user";
import { image_Base_Url } from "../config";
import { deleteAdvertiser, searchAdvertisers } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import DeleteAlert from "./Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";

interface AdvertiserProps {
  advertisersList: AdvertisersType[];
  setadvertisersList: Dispatch<SetStateAction<AdvertisersType[]>>;
  setindexes: Dispatch<SetStateAction<number>>;
  searchkey: string;
  setsearchkey: Dispatch<SetStateAction<string>>;
  selectedAdvertiser: AdvertisersType | undefined;
  setselectedAdvertiser: Dispatch<SetStateAction<AdvertisersType | undefined>>;
}

const AdvertisersComponent: React.FC<AdvertiserProps> = ({
  advertisersList,
  setadvertisersList,
  setindexes,
  searchkey,
  setsearchkey,
  selectedAdvertiser,
  setselectedAdvertiser,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const handleSearch = async (e: any) => {
    e.preventDefault();
    if (usr && searchkey.trim()!=="") {
      const usr2: UserType = usr;
      const advertisersList = await searchAdvertisers(usr2!.token, searchkey);

      setadvertisersList(advertisersList as AdvertisersType[]);
    }
  };
  // console.log(advertisersList);

  const handleDelete = async (advertiserId: string) => {
    try {
      const res = await deleteAdvertiser(usr!.token, advertiserId);
      if (res) {
        setadvertisersList((prev: AdvertisersType[]) =>
          prev.filter((item) => item.user._id !== advertiserId)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedAdvertiser?.user._id!);
    onDeleteAlertClose();
  };

  return (
    <div className="w-[95%] mx-auto max-h-[85vh] h-[80%] px-3 lg:px-10 md:px-6 rounded-2xl shadow-md bg-white">
      <div className="flex items-center justify-between">
        <p className="pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Advertisers List
          </p>
          <form onSubmit={handleSearch} className="relative">
            <Input
              onChange={(e) => setsearchkey(e.target.value)}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              value={searchkey}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={onOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className="flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/newadvertiser.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden m-0">
              Add New Advertisers
            </p>
          </Button>
        </p>
        <CreateAdivertisers
          selectedAdvertiser={selectedAdvertiser}
          setselectedAdvertiser={setselectedAdvertiser}
          setadvertisersList={setadvertisersList}
          isOpen={isOpen}
          onClose={onClose}
        />
      </div>

      <div
        className="overflow-y-auto hide-scrollbar"
        style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
      >
        <TableContainer
          fontFamily={"Montserrat"}
          className="py-4 dashboard_usertable"
        >
          <Table
            size="sm"
            variant={"simple"}
            background={"white"}
            borderRadius={"25px"}
          >
            <Thead className="font-bold">
              <Tr>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Name
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Email
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Phone
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Status
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Created Date
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {advertisersList.length > 0 &&
                advertisersList?.map((item: AdvertisersType, index: number) => (
                  <Tr key={index}>
                    <Td className="flex items-center">
                      {item.profile_pic !== null ? (
                        <img
                          src={
                            item ? `${image_Base_Url}${item.profile_pic}` : "jk"
                          }
                          alt=""
                          className="h-[50px] w-[55px] rounded-md"
                        />
                      ) : (
                        <img
                          src={"/dashboardicons/userIcon.png"}
                          alt=""
                          className="h-[50px] w-[55px] rounded-md"
                        />
                      )}

                      <p className="min-w-[150px] mb-0 max-w-[200px] text-[13px] pl-3 pt-2 text-wrap">
                        {item.name}
                      </p>
                    </Td>
                    <Td>
                      <p className="min-w-[150px] mb-0 max-w-[200px] text-[13px] pl-2 pt-2 text-wrap">
                        {item.user?.email}
                      </p>
                    </Td>
                    <Td>
                      <p className="max-w-[220px] mb-0 pl-2 pt-2 text-wrap">
                        {item.phone}
                      </p>
                    </Td>
                    <Td>
                      <p className="pl-2 pt-2 mb-0">
                        {item.user?.account_status === 0
                          ? "Active"
                          : "No-Active"}
                      </p>
                    </Td>
                    <Td>
                      <p className=" pt-2 mb-0 pl-1">
                        {item.createdAt &&
                          new Date(item.createdAt).toISOString().split("T")[0]}
                      </p>
                    </Td>
                    <Td>
                      <div className="flex m-auto justify-center items-start gap-1 min-w-[100px]">
                        <Tooltip label="View" placement={"top"}>
                          <img
                            onClick={() => {
                              setselectedAdvertiser(item);
                              setindexes(1);
                            }}
                            src="/dashboardicons/actionicon1.png"
                            className="h-[28px] w-[28px] cursor-pointer"
                            alt=""
                          />
                        </Tooltip>
                        <Tooltip label="Edit" placement={"top"}>
                          <img
                            onClick={() => {
                              setselectedAdvertiser(item);
                              onOpen();
                            }}
                            src="/dashboardicons/editAdicon.jpg"
                            className="h-[28px] w-[28px] cursor-pointer"
                            alt=""
                          />
                        </Tooltip>
                        <Tooltip label="Delete" placement={"top"}>
                          <img
                            onClick={() => {
                              setselectedAdvertiser(item);
                              onDeleteAlertOpen();
                            }}
                            src="/dashboardicons/deleteicon.png"
                            alt=""
                            className="h-[28px] w-[28px] cursor-pointer"
                          />
                        </Tooltip>
                      </div>
                    </Td>
                  </Tr>
                ))}
              <DeleteAlert
                isOpen={isDeleteAlertOpen}
                onClose={onDeleteAlertClose}
                onConfirmDelete={confirmDelete}
                label="Delete Advertiser"
              />
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AdvertisersComponent;
