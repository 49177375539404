import { useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotAuthenticatedLayout from "../components/layouts/NotAuthenticatedLayout";
import "./Home.css";
import { adminLogin } from "../Api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../redux/userSlice";
import { UserType } from "../@types/user";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
// import { Input } from "@chakra-ui/react";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setshowPassword] = useState(false);

  const handleLoginSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const user = (await adminLogin(email, password)) as UserType | null;
      // console.log(user);
      if (user) {
        dispatch(login(user));
        navigate("/dashboard");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.message, {
          position: "top-center",
        });
      } else {
        console.error("Unexpected error:", error);
      }
    }
  };

  return (
    <NotAuthenticatedLayout>
      {/* <ToastContainer /> */}
      <Toaster />
      <div className="w-[98vw] h-[100%] main-container flex flex-col items-center">
        <div className="h-[100vh] min-h-[650px]">
          <div className="w-[50vw] h-[100%] bg-[#3F1B5B] opacity-90 absolute z-20 top-0 right-0">
            <img
              className="z--10 text-[#FFFFFF] hidden md:block lg:block md:max-w-[50vw] md:w-[35vw] lg:max-h-[60%] top-0 opacity-20"
              src="design1.png"
              alt=""
            />
            <img
              className="z-30 bg-[#9D9D9] text-[#D9D9D9] w-[40vw] lg:w-[30vw] h-[30vh] opacity-80 absolute right-[0px] bottom-[0px]"
              src="Vector (1).png"
              alt=""
            />
            <img
              className="z-30 bg-[#9D9D9] text-[#D9D9D9] w-[40vw] lg:w-[30vw] h-[30vh] opacity-80 absolute pt-8 pl-8 right-[0px] bottom-[0px]"
              src="Vector.png"
              alt=""
            />
          </div>
        </div>

        <div className="absolute top-0 h-[100%] flex flex-col items-center justify-evenly pt-12">
          {/* login box */}
          <div className="w-[49vw] background-box lg:min-w-[430px] md:min-w-[370px] min-w-[300px] lg:w-[25vw] md:w-[45vw] h-[75%] min-h-[450px] max-h-[680px] bg-[#ffffff] rounded-[31px] z-30">
            {/* image */}
            <div className="w-full loginbox-logo">
              <img
                src="Flourish.png"
                className="m-auto h-24 md:h-24 lg:h-28"
                alt=""
              />
            </div>
            {/* welcome text and image */}
            <div className="welcome-tag">
              <p className="text-black font-[Georgia] font-bold">
                Welcome
                <span
                  style={{ fontFamily: "Rock Salt" }}
                  className="bg-[#FFE20A] px-[8px] text-black font-normal"
                >
                  Let’s Login
                </span>
              </p>
            </div>
            {/* inputs */}
            <form onSubmit={handleLoginSubmit}>
              <div className="w-[84%] inputs m-auto flex flex-col">
                <label>Email</label>
                <input
                  required
                  className="bg-[#F0F0F0] rounded-[12px]"
                  type="text"
                  placeholder="Enter Your Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="w-[84%] inputs m-auto flex flex-col ">
                <label className="mt-1"> Password</label>
                <div className="flex ">
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-2 lg:p-5 relative w-[100%] bg-[#F0F0F0] rounded-[12px]"
                    placeholder="Enter here"
                    value={password}
                    name="newPassword"
                    required
                    type={showPassword ? "text" : `password`}
                  />
                  <div className="z-40 relative  flex items-center justify-center">
                    {showPassword ? (
                      <FaRegEye
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                        className="absolute cursor-pointer right-4 "
                      />
                    ) : (
                      <FaRegEyeSlash
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                        className="absolute cursor-pointer right-4"
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* submit button */}
              <div className="w-[80%] signin-btn m-auto">
                <button
                  type="submit"
                  className="w-full bg-[#686628] rounded-[12px] text-center text-white"
                >
                  SIGN IN
                </button>
              </div>
            </form>
          </div>

          <div className="z-30 pt-12 text-white text-center">
            <p
              style={{ fontFamily: "Montserrat" }}
              className="text-xs md:text-sm lg:text-md"
            >
              Copyright © 2023 illif School of Theology Design All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </NotAuthenticatedLayout>
  );
};

export default Home;
