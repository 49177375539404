import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { CourseType, DropDownProps, UserType } from "../@types/user";
import { image_Base_Url } from "../config";
import { addDropDown, deleteCourse, deleteDropDown, editDropDown, getDropDownList, searchCourses } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import HashLoader from "react-spinners/HashLoader";
import DeleteAlert from "./Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";

interface CategoryComponentProps {
  setindexes: Dispatch<SetStateAction<number>>;
  dropdownitems: any[];
  categoryvalue: number;
  setcategoryvalue: React.Dispatch<React.SetStateAction<number>>;
  setdropdownitems: React.Dispatch<React.SetStateAction<any[]>>;
  setselectedCourseId: React.Dispatch<React.SetStateAction<string>>;
}

const CategoryComponent: React.FC<CategoryComponentProps> = ({
  setindexes,
  dropdownitems,
  categoryvalue,
  setcategoryvalue,
  setdropdownitems,
  setselectedCourseId,
}) => {
  const [loading, setloading] = useState(false);
  const [selectedItemId, setselectedItemId] = useState("");
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const {
    isOpen: isDropdownitemDeleteAlertOpen,
    onClose: onDropdownItemDeleteAlertClose,
    onOpen: onDropdownItemDeleteAlertOpen,
  } = useDisclosure();

  //add item
  const { isOpen : isSaveOpen, onOpen :onSaveOpen, onClose : onSaveClose } = useDisclosure();
  const [saving, setsaving] = useState(false);
  const [itemName, setItemName] = useState('');
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  //update item
  const { isOpen : isUpdateOpen, onOpen :onUpdateOpen, onClose : onUpdateClose } = useDisclosure();
  const [updating, setupdating] = useState(false);
  const [updatedItemName, setUpdatedItemName] = useState('');
  const [updateIndex, setUpdateIndex] = useState(0);
  const initialUpdateRef = React.useRef(null);
  const finalUpdateRef = React.useRef(null);

  const handleUpate = async () => {
    setupdating(true);
    try {
      if (usr) {
        console.log("updating function");
        console.log(dropdownitems[updateIndex]);
        const res:any = await editDropDown(usr.token, dropdownitems[updateIndex]._id, updatedItemName);
        if (res.message === "updated successfully") {
          toast.success("Updated successfully");
          dropdownitems[updateIndex] = res.data.updatedDropdown;
          onUpdateClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setupdating(false);
  };

  const handleSave = async () => {
    setsaving(true);
    try {
      if (usr) {
        const spelling:string [] = [itemName];
        const res:any = await addDropDown(usr.token, categoryvalue, spelling);
        const resData:any = res.data;
        if (resData.message === "drop items saved") {
          toast.success("Saved successfully");
          const addedItems:any [] = resData.data.dropDownItems;
          setdropdownitems((prev: any[]) =>
            [...addedItems,...prev]
          );
          onSaveClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
    setsaving(false);
  };

  const handleDelete = async (itemid: string) => {
    try {
      if (usr) {
        const ids:string [] = [itemid];
        const res:any = await deleteDropDown(usr.token, ids);
        if (res.message === "deleted successfully") {
          setdropdownitems((prev: any[]) =>
            prev.filter((item) => item._id !== itemid)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDropdownItemdelete = () => {
    handleDelete(selectedItemId);
    onDropdownItemDeleteAlertClose();
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            Select Category type
          </p>
          <form className="relative">
          
          <Select 
            onChange={(e) => {
              setcategoryvalue(parseInt(e.target.value));
            }}
            placeholder="Select option"
            minWidth={"170px"}
            width={"25vw"}
            maxWidth={"250px"}
            className="px-4"
            defaultValue={categoryvalue}
            >
            <option value={0}>Course Type</option>
            <option value={1}>Gender Identity</option>
            <option value={2}>Racial Identity</option>
            <option value={3}>Sexual Orientation</option>
            <option value={4}>Faith</option>
          </Select>
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={onSaveOpen}
            // onClick={() => {
            //   onOpen
            // }}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className="flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addnew.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden m-0">
              Add New Item
            </p>
          </Button>
        </p>
      </div>
      <div
        className="overflow-y-auto hide-scrollbar"
        style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
      >
        <TableContainer
          fontFamily={"Montserrat"}
          className="py-4 px-3 dashboard_usertable"
        >
          <Table
            size="sm"
            variant={"simple"}
            background={"white"}
            borderRadius={"25px"}
          >
            <Thead className="py-4 "></Thead>
            <Thead>
              <Tr>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                  minWidth={"220px"}
                  maxWidth={"230px"}
                >
                  Name
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  {" "}
                  Action
                </Th>
              </Tr>
            </Thead>

            {loading ? (
              <div className="w-[100vw] h-[40vh] flex justify-center items-center">
                <HashLoader />
              </div>
            ) : (
              <>
                {dropdownitems.length > 0 && (
                  <Tbody>
                    {dropdownitems.map((item: any, index: number) => (
                      <>
                        <Tr key={index}>
                          <Td>
                              <p className="min-w-[150px] max-w-[170px] pl-3 pt-2 text-wrap">
                                {item.spelling}
                              </p>
                          </Td>
                          <Td>
                            <div className="flex m-auto items-start gap-1 min-w-[100px]">
                              <Tooltip label="Edit" placement={"top"}>
                                <img
                                  src="/dashboardicons/editicon.png"
                                  className="h-[28px] w-[28px] cursor-pointer"
                                  alt=""
                                  onClick={() => {
                                    setUpdateIndex(index);
                                    setUpdatedItemName(item.spelling);
                                    console.log(updateIndex,updatedItemName);
                                    onUpdateOpen();
                                    // setselectedCourseId(item._id!);
                                    // setindexes(1);
                                  }}
                                />
                              </Tooltip>
                              <Tooltip label="Delete" placement={"top"}>
                                <img
                                  src="/dashboardicons/deleteicon.png"
                                  onClick={() => {
                                    setselectedItemId(item._id);
                                    onDropdownItemDeleteAlertOpen();
                                  }}
                                  alt=""
                                  className="h-[28px] w-[28px] cursor-pointer"
                                />
                              </Tooltip>
                            </div>
                          </Td>
                        </Tr>
                      </>
                    ))}
                  </Tbody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        <DeleteAlert
          isOpen={isDropdownitemDeleteAlertOpen}
          onClose={onDropdownItemDeleteAlertClose}
          onConfirmDelete={confirmDropdownItemdelete}
          label="delete"
        />

        {/* Add new item */}
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isSaveOpen}
          onClose={onSaveClose}
        >
        <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Category Item</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={false}>
                <FormLabel>Item Name</FormLabel>
                <Input
                 required
                 ref={initialRef}
                 placeholder='Item Name'
                 value={itemName}
                 onChange={(e) => setItemName(e.target.value)}
                  />
                <FormErrorMessage>Item name is required.</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
               isLoading = {saving}
               loadingText='Saving'
               colorScheme='blue' mr={3}
               onClick={()=>{
                handleSave();
               }}
               >
                Save
              </Button>
              <Button onClick={onSaveClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* on update item */}
        <Modal
          initialFocusRef={initialUpdateRef}
          finalFocusRef={finalUpdateRef}
          isOpen={isUpdateOpen}
          onClose={onUpdateClose}
        >
        <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Category Item</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={false}>
                <FormLabel>New Name</FormLabel>
                <Input
                 required
                 ref={initialUpdateRef}
                 placeholder='Item Name'
                 value={updatedItemName}
                 onChange={(e) => setUpdatedItemName(e.target.value)}
                  />
                <FormErrorMessage>Item name is required.</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
               isLoading = {updating}
               loadingText='Updating'
               colorScheme='blue' mr={3}
               onClick={()=>{
                handleUpate();
               }}
               >
                Update
              </Button>
              <Button onClick={onUpdateClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <ToastContainer/>
      </div>
    </div>
  );
};

export default CategoryComponent;
