import React, { useState } from "react";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import AdvertiseReject from "../modal/AdvertiseReject";
import { CreatedAd, UserType } from "../../@types/user";
import { updateAdStatus } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { image_Base_Url } from "../../config";
const NewlyAddedAds = ({
  setActiveAdsdata,
  setPendingAds,
  data,
  settabindex,
}: any) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [reject_reason, setreject_reason] = useState("");
  const [ad_id, setad_id] = useState("");
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const handleUpdateAd = async (ad_id: string) => {
    if (usr) {
      try {
        const res = await updateAdStatus(usr.token, ad_id, "1");
        if (res) {
          setActiveAdsdata((prev: CreatedAd[]) => [res, ...prev]);
          setPendingAds((prev: CreatedAd[]) =>
            prev.filter((item) => item._id !== ad_id)
          );
          settabindex(0);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleReject = async (ad_id: string) => {
    try {
      if (usr && ad_id && reject_reason) {
        const res = await updateAdStatus(usr.token, ad_id, "0", reject_reason);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
    onClose();
  };
  return (
    <div
      className="h-[50vh] overflow-scroll w-[98%] rounded-lg"
      style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
    >
      {data.length === 0 && (
        <>
          <div className="h-[100%] flex justify-center items-center">
            <p className="font-bold">No Newly Ads </p>
          </div>
        </>
      )}
      {data.length > 0 && (
        <>
          <TableContainer
            fontFamily={"Montserrat"}
            className="py-4 dashboard_usertable"
            scrollBehavior={"smooth"}
            style={{ scrollbarWidth: "none" }}
          >
            <Table
              size="sm"
              variant={"simple"}
              background={"white"}
              borderRadius={"25px"}
            >
              <Thead className="font-bold">
                <Tr>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Ad Title
                  </Th>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Descirption
                  </Th>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Ad Platform
                  </Th>

                  <Th textTransform={"none"} color={"black"}>
                    Target Audience
                  </Th>
                  <Th textTransform={"none"} color={"black"}>
                    Ad Duration
                  </Th>
                  {/* <Th color={"black"}>TotalAds</Th> */}
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    className="text-center"
                  >
                    Response
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.map((item: CreatedAd, index: number) => (
                    <Tr key={index}>
                      <Td className="flex items-center">
                        {/* <img src="/dashboardicons/courseIcon.png" alt="" /> */}
                        {item.image ? (
                          <img
                            src={`${image_Base_Url}${item.image}`}
                            alt=""
                            className="h-[64px] w-[64px] rounded-md"
                          />
                        ) : (
                          <img src="/dashboardicons/courseIcon.png" alt="" />
                        )}
                        <p className="min-w-[150px] mb-0 max-w-[200px] h-[100%] text-[13px] pl-3 pt-2 text-wrap">
                          {item.title}
                        </p>
                      </Td>
                      <Td>
                        <p className="min-w-[150px] h-[100%] mb-0 max-w-[200px] text-[13px] pl-3 pt-2 text-wrap">
                          {item.description}
                        </p>
                      </Td>
                      <Td>
                        <p className="max-w-[220px] pl-3 pt-2 text-wrap">
                          {item.ad_posting_platform}
                        </p>
                      </Td>
                      <Td className="pl-3">{item.audience}</Td>
                      <Td>{item.duration}</Td>

                      <Td className="">
                        <div className="m-0 p-0 flex h-[100%]  gap-2">
                          <Button
                            onClick={() => handleUpdateAd(item._id!)}
                            bgColor={"#00603A"}
                            margin={"0"}
                            color={"white"}
                          >
                            Accept
                          </Button>

                          <Button
                            onClick={() => {
                              setad_id(item._id!);
                              onOpen();
                            }}
                            bgColor={"#BF5529"}
                            margin={"0"}
                            color={"white"}
                          >
                            Reject
                          </Button>
                        </div>
                      </Td>
                    </Tr>
                  ))}
                {ad_id && (
                  <AdvertiseReject
                    isOpen={isOpen}
                    setreject_reason={setreject_reason}
                    onClose={onClose}
                    handleReject={handleReject}
                    ad_id={ad_id}
                  />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default NewlyAddedAds;
